import copy from "copy-to-clipboard";

export default {
  methods: {
    copyText(txt) {
      copy(txt);
      this.$message.info("内容已复制");
    }
  }
};
