<template>
  <Layout>
    <div class="app-act-list">
      <div class="tool">
        <a-form layout="inline">
          <a-button @click="showDetail(0)" type="primary">
            <a-icon type="file-add" /> 新建商品
          </a-button>
        </a-form>
        <div>
          <a-button @click="refresh">
            <a-icon type="reload" />
          </a-button>
        </div>
      </div>
      <div class="table-wrap">
        <a-table
          :columns="columns"
          :data-source="list"
          :pagination="pagination"
          :loading="loading"
          @change="handleTableChange"
        >
          <span slot="img" slot-scope="img">
            <img :src="img" class="product-img" />
          </span>
          <span slot="desc" slot-scope="desc">
            <span v-html="desc"></span>
          </span>
          <span slot="action" slot-scope="_, record">
            <a-button v-if="!record.is_delete" @click="showDetail(record.id)">
              编辑
            </a-button>
            <a-button
              v-if="!record.is_delete && record.type === 0"
              @click="showHouMa(record.id)"
              style="margin-top:5px"
            >
              活码
            </a-button>
            <a-button
              v-if="!record.is_delete && record.status === 0"
              type="danger"
              @click="del_audit(record)"
              style="margin-top:5px"
            >
              撤消
            </a-button>
            <a-button
              v-if="!record.is_delete && record.status === 5"
              type="danger"
              @click="del(record)"
              style="margin-top:5px"
            >
              下架
            </a-button>
          </span>
        </a-table>
      </div>
    </div>

    <!-- 添加 -->
    <a-modal
      :width="600"
      v-model="dialogVisible"
      :title="form.id > 0 ? '编辑' : '新建'"
      @ok="submit"
    >
      <a-form-model class="form-link" :model="form" ref="form">
        <template v-if="form.id === 0">
          <a-form-model-item
            required
            label="标题（会审核）"
            prop="name"
            :rules="[
              {
                required: true,
                message: '标题必填'
              }
            ]"
          >
            <a-input v-model="form.name" />
          </a-form-model-item>

          <a-form-model-item required label="主图（会审核）">
            <a-upload-dragger
              name="file"
              :multiple="false"
              :data="{ index: 1 }"
              listType="picture-card"
              :action="UPLOAD_URL"
              :headers="{ t: getToken }"
              withCredentials
              accept="image/*"
              :showUploadList="false"
              @change="handleUpload"
              :before-upload="beforeUpload"
            >
              <img
                style="width:100px;"
                v-if="form.list_pic_url"
                :src="form.list_pic_url"
              />
              <template v-else>
                <p class="ant-upload-drag-icon">
                  <a-icon type="inbox" />
                </p>
                <p class="ant-upload-text">
                  点击/拖放上传，建议1M以内，500x500像素
                </p>
              </template>
            </a-upload-dragger>
          </a-form-model-item>

          <a-form-model-item label="展示类型" prop="type" class="fluid-row">
            <a-radio-group v-model="form.type" button-style="solid">
              <a-radio-button :value="0">二维码</a-radio-button>
              <a-radio-button :value="1">公众号链接</a-radio-button>
              <a-radio-button :value="2">小程序</a-radio-button>
            </a-radio-group>
          </a-form-model-item>
        </template>

        <a-form-model-item v-if="form.type === 0" label="二维码图片" required>
          <a-upload-dragger
            name="file"
            :multiple="false"
            :data="{ index: 2 }"
            listType="picture-card"
            :action="UPLOAD_URL"
            :headers="{ t: getToken }"
            withCredentials
            accept="image/*"
            :showUploadList="false"
            @change="handleUpload"
            :before-upload="beforeUpload"
          >
            <img style="width:100px;" v-if="form.qrcode" :src="form.qrcode" />
            <template v-else>
              <p class="ant-upload-drag-icon">
                <a-icon type="inbox" />
              </p>
              <p class="ant-upload-text">
                点击/拖放上传，建议500k以内，300x300像素
              </p>
            </template>
          </a-upload-dragger>
        </a-form-model-item>

        <a-form-model-item
          v-if="form.type === 1"
          label="公众号链接"
          prop="gzh_url"
          required
          :rules="[
            {
              required: true,
              message: '公众号链接必填'
            }
          ]"
        >
          <a-input v-model="form.gzh_url" placeholder="https://" />
        </a-form-model-item>

        <template v-if="form.type === 2">
          <a-form-model-item
            label="小程序名称"
            prop="wxa_name"
            required
            :rules="[
              {
                required: true,
                message: '小程序名称必填'
              }
            ]"
          >
            <a-input v-model="form.wxa_name" placeholder="跳一跳" />
          </a-form-model-item>
          <a-form-model-item
            label="小程序原始ID"
            prop="wxa_username"
            required
            :rules="[
              {
                required: true,
                message: '原始ID必填'
              }
            ]"
          >
            <a-input v-model="form.wxa_username" placeholder="gh_xxxxxx" />
          </a-form-model-item>
          <a-form-model-item
            label="小程序页面路径"
            prop="wxa_path"
            required
            :rules="[
              {
                required: true,
                message: '页面路径必填'
              }
            ]"
          >
            <a-input
              v-model="form.wxa_path"
              placeholder="pages/index/index?a=1"
            />
          </a-form-model-item>
        </template>
      </a-form-model>
    </a-modal>

    <!-- 活码 -->
    <a-drawer
      width="inherit"
      :visible="huoma.show"
      @close="huoma.show = false"
      wrapClassName="huoma"
    >
      <div slot="title" class="huoma-title">
        <span>
          启用活码
        </span>
        <a-switch
          v-model="huoma.setting.enable"
          @change="changeSetting('huoma_enable')"
        />
        <span>轮换规则</span>
        <a-select
          placeholder="规则"
          v-model="huoma.setting.type"
          @change="changeSetting('huoma_type')"
        >
          <a-select-option :value="0">顺序</a-select-option>
          <a-select-option :value="1">随机</a-select-option>
        </a-select>
      </div>
      <div class="tool">
        <a-form layout="inline">
          <a-button @click="addHuoMa" icon="plus">
            添加
          </a-button>
          <a-button @click="submitHuoMa" type="primary">
            保存
          </a-button>
        </a-form>
      </div>
      <a-form-model class="form-link" :model="huoma.list" ref="form">
        <table class="huoma-items">
          <thead class="">
            <th class="enable">启用</th>
            <th class="qrcode">二维码</th>
            <th class="name">名称</th>
            <th class="sort">排序</th>
            <th class="max">限额</th>
            <th class="num">展示</th>
            <th class="action">操作</th>
          </thead>
          <tr class="row" v-for="(item, index) in huoma.list" :key="item.id">
            <td style="text-align:center;">
              <a-checkbox v-model="item.enable" />
            </td>
            <td>
              <a-upload
                :data="{ index: index + 1000 }"
                listType="picture-card"
                :action="UPLOAD_URL"
                :headers="{ t: getToken }"
                withCredentials
                accept="image/*"
                :showUploadList="false"
                @change="handleUpload"
                :before-upload="beforeUpload"
              >
                <img v-if="item.qrcode" :src="item.qrcode" style="width:50px" />
                <div v-else>
                  <a-icon type="plus" />
                  <div class="ant-upload-text">
                    上传
                  </div>
                </div>
              </a-upload>
            </td>
            <td>
              <a-input v-model="item.name" :max-length="20" />
            </td>
            <td class="sort">
              <a-input-number v-model="item.sort" :min="0" :max="999" />
            </td>
            <td class="max">
              <a-input-number v-model="item.max" :min="1" :max="9999" />
            </td>
            <td class="num td">
              {{ item.num }}
            </td>
            <td class="action">
              <a-button type="danger" @click="delHuoMaRow(index)">
                删除
              </a-button>
            </td>
          </tr>
          <tr v-if="huoma.list.length <= 0">
            <td colspan="7">
              <a-empty />
            </td>
          </tr>
        </table>
      </a-form-model>
    </a-drawer>
  </Layout>
</template>

<script>
import Layout from "@/layout/index.vue";

import { mapState } from "vuex";
import copyMixin from "@/mixins/copy";
import uploadMixin from "@/mixins/upload";

const ActSvc = require("@/service/activity");
const HuoMaSvc = require("@/service/huoma");

const DEFAULT_ACT = {
  id: 0,
  name: "",
  list_pic_url: "",
  type: 0,
  qrcode: "",
  gzh_url: ""
};

const ACT_STATUS = {
  init: 0, // 初始值
  on: 5, // 上架
  off: 11, // 自主下架
  fail: 13 // 违规下架/风控系统下架
};

const ACT_STATUS_TEXT = {
  [ACT_STATUS.init]: "审核中",
  [ACT_STATUS.on]: "上架中",
  [ACT_STATUS.off]: "自主下架",
  [ACT_STATUS.fail]: "违规下架"
};

const TYPE_NAME = ["二维码", "公众号链接", "小程序"];

export default {
  name: "ActivityList",

  data() {
    return {
      list: [],
      pagination: {
        showTotal: total => `共 ${total} 条记录`
      },
      loading: false,

      dialogVisible: false,
      form: {
        id: 0,
        name: "",
        list_pic_url: "",
        type: 0,
        qrcode: "",
        gzh_url: ""
      },

      huoma: {
        show: false,
        setting: { enable: false, type: 0 },
        list: []
      }
    };
  },

  mixins: [uploadMixin, copyMixin],

  computed: {
    ...mapState({
      user: state => state.user
    }),

    columns() {
      return [
        {
          title: "主图",
          align: "center",
          dataIndex: "list_pic_url",
          key: "list_pic_url",
          width: 60,
          scopedSlots: { customRender: "img" }
        },
        {
          title: "标题",
          dataIndex: "desc",
          key: "desc",
          scopedSlots: { customRender: "desc" }
        },

        {
          title: "点击数",
          dataIndex: "view_count",
          key: "view_count",
          width: 80,
          align: "right"
        },

        {
          title: "操作",
          key: "action",
          align: "center",
          width: 80,
          scopedSlots: { customRender: "action" }
        }
      ];
    }
  },

  methods: {
    handleUpload({ file }) {
      if (file.status != "done") return;

      const { code, msg, data } = file.response;
      if (code == "0") {
        const { index, url } = data;

        // 活码
        if (index >= 1000) {
          const idx = index - 1000;
          let row = this.huoma.list[idx];
          row.qrcode = url;
          this.huoma.list = [
            ...this.huoma.list.slice(0, idx),
            row,
            ...this.huoma.list.slice(idx + 1)
          ];
          return;
        }

        if (index === 1) {
          this.form.list_pic_url = url;
        } else {
          this.form.qrcode = url;
        }
      } else {
        this.$message.error("上传失败，错误：" + msg);
      }
    },

    async handleTableChange(pagination) {
      const pager = { ...this.pagination };
      pager.current = pagination.current;
      this.pagination = pager;

      const { pageSize, current: page } = pagination;
      await this.fetch({
        pageSize,
        page
      });
    },

    async fetch(params = {}) {
      this.loading = true;

      try {
        const data = await ActSvc.list({
          page: 1,
          pageSize: 10,
          ...params
        });

        const pagination = {
          ...this.pagination,
          total: data.cnt,
          current: data.page
        };
        this.list = data.res.map(record => {
          let typeName = TYPE_NAME[record.type];

          // 小程序名称
          if (record.type === 2) {
            typeName += `:${record.wxa_name}`;
          }

          const statusName = record.is_delete
            ? "已下架"
            : ACT_STATUS_TEXT[record.status] || "审核中";
          let reason = record.reject_reason || "";
          if (reason) {
            reason += "<br/>";
          }

          const createdAt = window
            .moment(record.created_at)
            .format("YYYY-MM-DD hh:mm");

          const desc = `${record.name}<br/>
          ${statusName} | ${typeName}<br/>
          ${reason}
          ${createdAt}`;

          return {
            ...record,
            desc
          };
        });
        this.pagination = pagination;

        this.loading = false;
      } catch (err) {
        this.loading = false;

        console.error(err);
        this.$message.error("加载数据出错，错误：" + err.message);
      }
    },

    async del(record) {
      this.$confirm({
        title: "操作提示",
        content: `确认删除此商品「${record.name}」吗？`,

        onOk: () => {
          this._delete(record.id);
        },
        onCancel() {}
      });
    },

    async _delete(id) {
      try {
        await ActSvc.del(id);

        await this.fetch();
      } catch (err) {
        console.error(err);
        this.$message.error("删除失败，错误：" + err.message);
      }
    },

    async del_audit(record) {
      this.$confirm({
        title: "操作提示",
        content: `确认撤消此商品「${record.name}」吗？`,

        onOk: () => {
          this._delete_audit(record.id);
        },
        onCancel() {}
      });
    },

    async _delete_audit(id) {
      try {
        await ActSvc.del_audit(id);

        await this.fetch();
      } catch (err) {
        console.error(err);
        this.$message.error("撤消失败，错误：" + err.message);
      }
    },

    async showDetail(id) {
      if (id <= 0) {
        this.form = JSON.parse(JSON.stringify(DEFAULT_ACT));
      } else {
        try {
          this.form = await ActSvc.detail(id);
        } catch (err) {
          console.error(err);
        }
      }
      this.dialogVisible = true;
    },

    async submit() {
      this.$refs.form.validate(async valid => {
        if (valid) {
          let {
            id,
            name,
            list_pic_url,
            type,
            qrcode,
            gzh_url,

            wxa_name,
            wxa_username,
            wxa_path
          } = this.form;

          try {
            let val = {
              id,
              name,
              list_pic_url,
              type,
              qrcode,
              gzh_url,

              wxa_name,
              wxa_username,
              wxa_path
            };

            if (!list_pic_url) {
              this.$message.info("主图必填");
              return;
            }

            switch (type) {
              case 0:
                if (!qrcode) {
                  this.$message.info("二维码必填");
                  return;
                }
                break;
              case 1:
                if (!gzh_url) {
                  this.$message.info("公众号链接必填");
                  return;
                }
                break;
              case 2:
                if (!wxa_name) {
                  this.$message.info("小程序名称必填");
                  return;
                }
                if (!wxa_username) {
                  this.$message.info("小程序原始ID必填");
                  return;
                }
                if (!wxa_path) {
                  this.$message.info("小程序页面路径必填");
                  return;
                }
                break;
            }

            await ActSvc.create(val);
            this.$message.info("保存成功");

            this.$refs.form.resetFields();

            this.dialogVisible = false;

            await this.fetch({ page: 1 });
          } catch (err) {
            console.error(err);
            this.$message.error("保存失败，错误：" + err.message);
          }
        } else {
          console.log("error submit!!");

          return false;
        }
      });
    },

    async refresh() {
      await this.fetch();
    },

    async showHouMa(id) {
      try {
        const { setting, list } = await HuoMaSvc.list({ id });
        this.huoma.id = id;
        this.huoma.setting = setting;
        this.huoma.list = list;

        this.huoma.show = true;
      } catch (err) {
        console.error(err);
      }
    },
    async changeSetting(op) {
      const id = this.huoma.id;
      const val = this.huoma.setting[op.slice("huoma_".length)];

      try {
        await HuoMaSvc.set({ id, op, val: Number(val) });
        this.$message.info("保存成功");
      } catch (err) {
        console.error(err);
        this.$toast.show("提交失败，请重试");
      }
    },
    addHuoMa() {
      this.huoma.list.push({
        enable: true,
        qrcode: "",
        name: "",
        max: 200,
        num: 0,
        sort: "0"
      });
    },
    delHuoMaRow(index) {
      this.huoma.list.splice(index, 1);
    },

    async submitHuoMa() {
      const id = this.huoma.id;

      for (const row of this.huoma.list) {
        if (!row.qrcode) {
          this.$message.info("二维码必填");
          return;
        }
      }

      try {
        const list = this.huoma.list.map(x => ({
          id: x.id || 0,
          name: x.name,
          qrcode: x.qrcode,
          max: x.max,
          sort: x.sort,
          enable: x.enable
        }));

        await HuoMaSvc.edit({ id, list });
        this.$message.info("保存成功");

        this.huoma.show = false;
      } catch (err) {
        console.error(err);
        this.$message.error("保存失败，错误：" + err.message);
      }
    }
  },

  async mounted() {
    await this.fetch();
  },

  components: {
    Layout
  }
};
</script>

<style lang="scss">
.app-act-list {
  .tool {
    padding-bottom: 20px;
    display: flex;
    justify-content: space-between;
  }
  .table-wrap {
    .ant-table-pagination.ant-pagination {
      float: none;
      text-align: center;
    }
  }

  .product-img {
    width: 60px;
  }
}

.huoma .ant-drawer-content-wrapper {
  width: 750px !important;

  .huoma-title {
    display: flex;
    align-items: center;

    > span {
      margin: 0 10px;
    }
  }

  .tool {
    padding: 0 0px 20px;

    .ant-form {
      display: flex;
      justify-content: space-between;
    }
  }
}
@media only screen and (max-width: 750px) {
  .huoma .ant-drawer-content-wrapper {
    width: inherit !important;
  }
}

.huoma-items {
  width: 100%;

  .ant-upload {
    width: 60px;
    height: 60px;
    margin: 0;
    margin-top: 4px;
  }

  tr {
    background: #fff;
    transition: background 0.3s;

    &:hover {
      background: #e6f7ff;
    }
  }

  th {
    color: rgba(0, 0, 0, 0.85);
    font-weight: 500;
    text-align: left;
    background: #fafafa;
    border-bottom: 1px solid #e8e8e8;
    transition: background 0.3s ease;
    white-space: nowrap;
    height: 53px;
  }

  th,
  td {
    padding: 2px 10px;
    transition: background 0.3s;
    border-bottom: 1px solid #e8e8e8;
    white-space: nowrap;

    .ant-select {
      width: 100%;
      max-width: 300px;
    }
  }

  .enable {
    width: 32px;
  }
  .qrcode {
    width: 60px;
  }
  .name {
    min-width: 80px;
  }
  .sort {
    width: 60px;

    .ant-input-number {
      width: 60px;
    }
  }
  .max {
    width: 60px;

    .ant-input-number {
      width: 60px;
    }
  }
  .num {
    width: 80px;

    &.td {
      text-align: right;
    }
  }

  .action {
    width: 60px;
  }
}
</style>
