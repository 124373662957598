import request from "./request";

/**
 *  活码
 */

/**
 * 查询
 */
export const list = data => request.get("/hm/q", { params: data });

/**
 * 配置
 */
export const set = data => request.post("/hm/set", data);

/**
 * 编辑
 */
export const edit = data => request.post("/hm/", data);
