import request from "./request";

/**
 *  商品
 */

/**
 * 查询
 */
export const list = data => request.get("/act/q", { params: data });

/**
 * 创建
 */
export const create = data => request.post("/act", data);

/**
 * 详情
 */
export const detail = id => request.get("/act/detail", { params: { id } });

/**
 * 撤消审核
 */
export const del_audit = id => request.post("/act/del_audit", { id });

/**
 * 删除
 */
export const del = id => request.post("/act/del", { id });
